import { Injectable } from '@angular/core';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../../appInterfaces/ICartDetails';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  api: string;
  loading = new BehaviorSubject(false);
  cart = new BehaviorSubject<any>(null);
  private cartUpdated = new BehaviorSubject<boolean>(false);
  cartUpdated$ = this.cartUpdated.asObservable();

  constructor(
    private apiConfig: HttpConfigService,
  ) {
    this.api = this.apiConfig.getBaseUrl + 'cart-details';
  }



  getCart(): void {
      this.loading.next(true);
      this.apiConfig.get(this.api, { withCredentials: true }).subscribe((res: any[]) => {
          console.log('Fetched cart items:', res); // Log fetched items
          this.loading.next(false);
          res.forEach(item => {
              this.calculatePerItemPrice(item);
              console.log('Item with calculated price:', item); // Log price calculations
          });
          this.cart.next(res);
      },
      err => {
          console.error('Error fetching cart:', err);
          this.loading.next(false);
          this.cart.next(null);
      });
  }

  clearCart() {
    // Existing code to clear the cart
    this.cartUpdated.next(true); // Emit true after clearing the cart
  }

  removeItem(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.delete(this.api + '/' + item.id, { withCredentials: true }).subscribe(res => {
      this.getCart();
    });
  }



  addToCart(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.post(this.api, item, { withCredentials: true }).subscribe(res => {
      this.getCart();
    });
  }



  getCartDetailById(cartId: number): ICartDetails | null {
    if (!this.cart.value) {
      return null;
    }

    for (const item of this.cart.value) {
      if (item.id === cartId) {
        return { ...item };
      }
    }
    return null;
  }



  // ######### helper methods

  /**
   * calculate per item price
   * @param item
   */
  calculatePerItemPrice(item:any): void {
    let price = item.productModel.basePrice;
    if (item.productModel.discount) {
      price = item.productModel.discount.discount;
    }
    item.cartDetailsVariantModelList.forEach((variant:any) => {
      price = price + variant.price;
    });
    item.cartDetailsAddOnModelList.forEach((addOn:any) => {
      price = price + addOn.addOnModel.addOnPrice;
    });
    price = price * item.quantity;
    item.price = price;
  }



  /**
   * get total price of cart
   * @param cartDetails
   */
  totalPriceOfCart(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach((element:any) => {
        if(element?.loyaltyPointsSpent > 0) {
          total = total + 0;
        } else {
          total = total + element.price;
        }
      });
    }
    return total;
  }


  /**
   * total loyaltyPointsSpend
   * @returns
   */
  totalLoyaltyPointsSpent(): any {
    let totalPointsSpent = 0;
    if (this.cart.value) {
      this.cart.value.forEach((element: any) => {
        if(element?.loyaltyPointsSpent > 0) {
          totalPointsSpent = totalPointsSpent + element?.loyaltyPointsSpent;
        }
      });
    }
    return totalPointsSpent;
  }

}
